<template>
  <div class="row">
    <!-- Start Single Service  -->
    <v-col
      xl="3"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="#">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContent: [
        {
          icon: "key",
          title: "Brand Development",
          desc: `Craft a unique brand identity that stands out`
        },
        {
          icon: "code",
          title: "Web Design & Development",
          desc: `Custom websites tailored to your brand`
        },
        {
          icon: "pen-tool",
          title: "Content Creation",
          desc: `Photo/Video content that drives engagement`
        },
        {
          icon: "trending-up",
          title: "Digital Marketing",
          desc: `SEO, PPC, social media management, and more`
        }
      ]
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    }
  }
};
</script>
