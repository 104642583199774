<template>
  <div>
    <!-- Start Header Area -->
    <Header class="digital-agency-header color-white">
      <img slot="logo" src="../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <SliderOne />
    <!-- End Slider Area -->

    <!-- Start Service Area  -->
    <div class="rn-service-area rn-section-gap bg_color--1" id="service">
      <v-container>
        <v-row>
          <v-col lg="8">
            <h2 class="heading-intro">We believe every brand has untapped potential</h2>
            <div class="feature-area">
              <h3 class="heading-title mt--20 fontWeight500 lineheight--1-8">
                At BeMagnified, we specialize in rebranding and revamping
                businesses to ensure you stand out in today's competitive
                market.
              </h3>
            </div>
          </v-col>
        </v-row>
        <Service class="interior-services" />
      </v-container>
    </div>

    <!-- Start Portfolio Area  -->
    <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner">
        <div class="container">
          <div class="row">
            <v-col lg="12">
              <div class="section-title text-center">
                <h2 class="heading-title">Real Clients, Real Results</h2>
                <p>Here are some of our latest creations.</p>
              </div>
            </v-col>
          </div>
        </div>
        <Portfolio />
      </div>
    </div>
    <!-- Start Portfolio Area  -->

    <div class="pb--50">
      <UnlockYourBrand />
    </div>

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
<script>
import Header from "../components/header/HeaderNoNav";
import SliderOne from "../components/slider/SliderOne";
import Service from "../components/service/ServiceThree.vue";
import Portfolio from "../components/portfolio/PortfolioHomepage";
import UnlockYourBrand from "../components/about/UnlockYourBrand";
import Testimonial from "../components/testimonial/Testimonial";
import CallToAction from "../components/callto-action/CallToAction.vue";
import Footer from "../components/footer/Footer";

export default {
  components: {
    Header,
    SliderOne,
    Service,
    Portfolio,
    UnlockYourBrand,
    Testimonial,
    CallToAction,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    let liveChatScript = document.createElement("script");
    liveChatScript.setAttribute("src", "//js-na1.hs-scripts.com/22756266.js");
    document.head.appendChild(liveChatScript);
  }
};
</script>

<style lang="scss">
.heading-intro {
  font-size: 24px;
  font-weight: 400;
  color: #F3AD00;
  text-transform: uppercase;
}
.bg-white--service {
  .single-service.service__style--2.bg-color-gray a {
    background: #fff;
    text-align: center;
  }
}
.rn-section-gap {
  padding: 80px 0;
}
</style>
